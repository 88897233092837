import {useQuery} from "@tanstack/react-query";
import apiClient from "./apiClient";
import {config} from "../config";

export const useFetchDataStatus = (isAuthenticated) => {
    return useQuery({
            queryKey: [`data-status`],
            queryFn: async () => {
                    const response = await apiClient.get(`${config.REACT_APP_BACKEND_HOST}/configure/data/status`, {
                    })
                    return await response.data
            },
            staleTime: 1000 * 60 * 60 * 36,
            cacheTime: 1000 * 60 * 60 * 36, // 36 hour -- or until invalidated
            enabled: isAuthenticated
        })
    }