import React from 'react';
import { Link } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {BsBuildingFillGear} from "react-icons/bs";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import PeopleIcon from '@mui/icons-material/People';
import {MdPerson} from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {GoGear} from "react-icons/go";
import {MdAttachMoney} from "react-icons/md";
import {useAppContext} from "../../libs/appContextLib";
import {config} from "../../config";
import Avatar from "@mui/material/Avatar";
import {FormControlLabel, Stack, Switch} from "@mui/material";
import {styled} from "@mui/material/styles";
import Drift from "../DriftChat/Drift";
import {formatRelative, parseISO} from "date-fns";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import {IoExtensionPuzzleOutline} from "react-icons/io5";
import apiClient from "../../queries/apiClient";


const TopRightMenuItem = styled(MenuItem)(({theme}) => ({
    padding: theme.spacing(1.45),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(0),
    '&.MuiListItemIcon': {
        minWidth: "30px",
    },

    '& .MuiListItemIcon-gutters': {
        padding: "0px"
        // padding: theme.spacing(1.45)
    },
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        minWidth: "40px",
        "&:focus": {
            backgroundColor: theme.palette.primary.main
        },
        '&:hover': {
            // backgroundColor: "#393A43",
            // '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            // color: theme.palette.common.white,
            // backgroundColor: "#393A43",
            // },
        }
    }
}));

function TopRightMenu(props) {
    const {currUser, trialStatus} = useAppContext();
    // const { currUser.role } = useAppContext();
    // const { currentCustomerId } = useAppContext();

    const [anchorEl, setAnchorEl] = React.useState(null);
    // const [portalSessionUrl, setPortalSessionUrl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };




    const handleNewStripePortalSession = async evt => {
        evt.preventDefault();
        return apiClient.post(`${config.REACT_APP_BACKEND_HOST}/stripe-new-portal-session`,
            {
                body:
                    {
                        customer_id: currUser.customerId,
                    }
            },
            {
                params: {
                    stripe_customer_id: props.stripeCustomerId,
                    // stripe_customer_id: "cus_QUDsutmqMIyGBJ",
                    qflow_redirect_url: config.REACT_APP_FRONTEND_HOST
                }
            })
            .then((response) => {
                window.location.replace(response.data['new_session'].url)

            }).catch(error => {
                alert(error)
            })
    }

    const usersLink = `/manage-users/q_none/25/0,1,2,3,4,5,6,7,8,9/9%5B0%5D=active/true,true,true,true,true,true,true,true,true,true/q_none`

    const today = new Date();

    return (
        <Grid container alignItems='center' justifyContent='flex-end'>
            {trialStatus.status === "trialing" &&
                <Hidden mdDown>
                    <Grid item xs={10} md={6}>
                        <Grid container spacing={3} alignItems={"center"}>
                            <Grid item>
                                <Typography color={"tertiary.main"}><b>Trial
                                    ends {formatRelative(parseISO(trialStatus.trialingUntil), today)}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={6}>

                                <Stack spacing={2} direction={"row"}>
                                    <Grid item>
                                        <Button
                                            size="small"
                                            component={Link}
                                            // onClick={handleShowMore}
                                            to={"/admin/billing"}
                                            variant={"contained"}
                                            color={"tertiary"}
                                            // style={{marginLeft: 36}}
                                        >See plans</Button>
                                    </Grid>

                                    <Grid item> <Button color="tertiary" data-cal-link="team/qflow/30-min-call"
                                                        data-cal-config='{"layout":"month_view"}' data-cal-namespace=""
                                                        variant="outlined"
                                                        size="small">Book call</Button></Grid>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
            }

            {trialStatus.status === "trial_ended" &&
                <Hidden mdDown>
                    <Grid item xs={10} md={6}>
                        <Grid container spacing={3} alignItems={"center"}>
                            <Grid item>
                                <Typography color={"tertiary.main"}><b>Trial
                                    ended {formatRelative(parseISO(trialStatus.trialingUntil), today)}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={6}>

                                <Stack spacing={2} direction={"row"}>
                                    <Grid item>
                                        <Button
                                            size="small"
                                            // onClick={handleShowMore}
                                            to={"/admin/billing"}
                                            variant={"contained"}
                                            color={"tertiary"}
                                            // style={{marginLeft: 36}}
                                        >See plans</Button>
                                    </Grid>

                                    <Grid item> <Button color="tertiary" data-cal-link="team/qflow/30-min-call"
                                                        data-cal-config='{"layout":"month_view"}' data-cal-namespace=""
                                                        variant="outlined"
                                                        size="small">Book call</Button></Grid>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
            }

            <Grid item>
                <IconButton
                    aria-label="More"
                    disableRipple={true}
                    aria-haspopup="true"
                    variant="contained"
                    // color="secondary"
                    onClick={handleClick}
                    size="large">
                    <Avatar variant='rounded' size='small' sx={{
                        padding: 0,
                        // fontSize: '0.8em',
                        width: "30px",
                        height: "30px",
                        backgroundColor: "primary.main"
                    }}>{currUser.firstName.slice(0, 1).toUpperCase() + currUser.lastName.slice(0, 1).toUpperCase()}</Avatar>

                </IconButton>

            </Grid>

            <Menu
                elevation={1}
                // getContentAnchorEl={null}
                disableAutoFocusItem
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{'& .MuiPaper-root': {backgroundColor: ""}}}
            >
                <Grid container justifyContent="center" sx={{paddingLeft: 4, paddingRight: 0, maxWidth: 300}}>
                    <Grid item xs={12}>
                        <Typography color="primary.main" variant="caption">authenticated as </Typography>
                        <Typography color="primary.main" fontWeight={900}>
                            AGENT {currUser.lastName && currUser.lastName.toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary.main" variant={"caption"}>
                            {currUser.email && currUser.email.toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary.main" variant={"caption"}>
                            {currUser.companyName && currUser.companyName.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>
                <TopRightMenuItem component={Link} to="/settings" key="/settings">
                    {/*<ListItem component={Link} to="/settings" key="/settings">*/}
                    <ListItemIcon>
                        <MdPerson/>
                    </ListItemIcon>
                    <ListItemText primary="Profile"/>
                    {/*</ListItem>*/}
                </TopRightMenuItem>

                {currUser.role === "admin_user" &&
                    <TopRightMenuItem
                        component={Link} to={usersLink} key={usersLink}
                        // disabled={!dataSetupComplete}
                    >
                        {/*<ListItem >*/}
                        <ListItemIcon>
                            <PeopleIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText primary="Manage Users"/>
                        {/*</ListItem>*/}
                    </TopRightMenuItem>
                }

                {currUser.role === "admin_user" &&
                    <TopRightMenuItem component={Link} to="/admin/company/integrations"
                                      key="/admin/company/integrations">
                        <ListItemIcon>
                            {/*<SettingsEthernetIcon fontSize={"14px"}/>*/}
                            <IoExtensionPuzzleOutline size="1em"/>
                        </ListItemIcon>
                        <ListItemText primary="Integrations"/>
                    </TopRightMenuItem>
                }


                {(currUser.customerId === "e34e494c-86e9-41db-96ab-fcb651a4ddc0" ? currUser.email.indexOf('appadmin') > -1 : currUser.role === "admin_user") &&
                    <TopRightMenuItem component={Link} to='/admin/company/configure-data/6'
                                      key='/admin/company/configure-data/6'>
                        <ListItemIcon>
                            <GoGear/>
                        </ListItemIcon>
                        <ListItemText primary="Configuration"/>
                    </TopRightMenuItem>
                }

                {currUser.role === "admin_user" &&
                    <TopRightMenuItem component={Link} to="/admin/company/settings" key="/admin/company/settings">
                        <ListItemIcon>
                            <BsBuildingFillGear fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText primary="Company Settings"/>
                    </TopRightMenuItem>
                }


                {currUser.role === "admin_user" && !props.stripeCustomerId &&
                    <TopRightMenuItem component={Link} to='/admin/billing' key='/admin/billing'>
                        <ListItemIcon>
                            <MdAttachMoney fontSize={20}/>
                        </ListItemIcon>
                        <ListItemText primary="Billing"/>
                    </TopRightMenuItem>
                }
                {currUser.role === "admin_user" && props.stripeCustomerId &&
                    <TopRightMenuItem onClick={handleNewStripePortalSession} key="stripe">
                        <ListItemIcon>
                            <MdAttachMoney fontSize="medium"/>
                        </ListItemIcon>
                        <ListItemText primary="Billing"/>
                    </TopRightMenuItem>
                }

                <TopRightMenuItem component={Link} to="/faq" key='faq'>
                    <ListItemIcon>
                        <LiveHelpIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText primary="FAQs"/>
                </TopRightMenuItem>

                <TopRightMenuItem
                >
                    <FormControlLabel
                        control={<Switch
                            checked={props.chatWidgetVisible}
                            size='small'
                            color={"secondaryNew.main"}
                            // onChange={props.handleShowByMonth}
                            onChange={(e) => {
                                props.handleChatWidgetVisible(e)
                                setTimeout(() => {
                                    handleClose()
                                }, 500)
                            }
                            }
                        />}
                        data-html2canvas-ignore="true"
                        label={<span style={{paddingLeft: 8}}>Human Chat</span>}
                        // color="primary"
                    />
                </TopRightMenuItem>

                {/*<TopRightMenuItem key='liveChat'>*/}
                {/*    <ListItemIcon>*/}
                {/*        <LiveHelpIcon fontSize="small"/>*/}
                {/*    </ListItemIcon>*/}
                {/*    <ListItemText primary="Live Chat"/>*/}
                {/*</TopRightMenuItem>*/}

                {props.chatWidgetVisible &&
                    <div style={{display: props.chatWidgetVisible ? undefined : 'none', marginRight: "10vw"}}
                         key={props.chatWidgetVisible.toString() + "test"}>
                        <Drift appId="e54ayt5wt7pc"
                               userId={currUser.id}
                               attributes={{email: currUser.email, company: currUser.customerId}}
                               config={{horizontalOffset: 70}}
                        />
                    </div>
                }
                <TopRightMenuItem onClick={props.handleClickedLogout} key='logout'>
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText primary="Sign Out"/>
                </TopRightMenuItem>
            </Menu>
        </Grid>
    );
}

export default React.memo(TopRightMenu);