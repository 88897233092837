import React, {useCallback, useEffect, useRef, useState} from "react";
import {Alert, Grid, List, ListItem, Stack, ToggleButton} from "@mui/material";
// import axios from "axios";
import {config} from "../config";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import useEventListener from "@use-it/event-listener";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Drawer from "@mui/material/Drawer";
import {AiOutlineCaretRight} from "react-icons/ai";
import Typography from "@mui/material/Typography";
import Markdown from "react-markdown";
import "./qPilotStyles.css";
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import 'katex/dist/katex.min.css'
import rehypeRaw from "rehype-raw";
import {useAppContext} from "../libs/appContextLib";
import useWebSocket, {ReadyState} from "react-use-websocket";
// import {usePreviousValue} from "../libs/usePreviousValue";
import Chip from "@mui/material/Chip";
import {Auth} from 'aws-amplify';

const QBearMascot = "/images/qpilot-avatar-image.png";

export default function QPilotChatbot(props) {

    const [currentPrompt, setCurrentPrompt] = React.useState("")

    //Public API that will echo messages sent to it back to the client
    const [socketUrl, setSocketUrl] = useState(config.websocket.URL);
    const [messageHistory, setMessageHistory] = useState([]);
    const [aiMessage, setAiMessage] = useState("");

    const handleCurrentPrompt = React.useCallback((e) => {
        setCurrentPrompt(e.target.value)
    }, [])


    function getOS() {
        const userAgent = window.navigator.userAgent,
            platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
            macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        let os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (/Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    }

    const operatingSystem = getOS()

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth", block: "end"})

    }

    useEffect(() => {
        scrollToBottom()
    }, [aiMessage, messageHistory]);


    const [commandKeyDown, setCommandKeyDown] = React.useState(false)


    function handler({key}) {
        if (String(key) === 'Meta') {
            setCommandKeyDown(!commandKeyDown)
        }
        if (commandKeyDown && String(key) === "Enter" && currentPrompt && currentPrompt.length > 0) {
            handleClickSendMessage()
            // props.sendChat(false, currentPrompt)
            setCommandKeyDown(false)
            setCurrentPrompt("")
        }
    }

    useEventListener('keydown', handler);

    const remarkMathOptions = {
        singleDollarTextMath: false,
    };

    const processText = (content) => {
        return content
            .replace(/\[/g, '$$$') // Replace all occurrences of [ with $$
            .replace(/\]/g, '$$$') // Replace all occurrences of ] with $$
            .replace(/\(/g, '$$$') // Replace all occurrences of ( with $$
            .replace(/\)/g, '$$$'); // Replace all occurrences of ) with $$
    }


    const [isLoadingMessage, setIsLoadingMessage] = React.useState(false)
    const [currentIncomingMessage, setCurrentIncomingMessage] = React.useState("")
    // WEBSOCKET LOGIC START


    const {currUser} = useAppContext();
    const {
        sendJsonMessage,
        lastJsonMessage,
        sendMessage,
        lastMessage,
        readyState
    } = useWebSocket(socketUrl, {
        filter: false,
        share: false,
        queryParams: {
            jwt: currUser.authToken,
            channel: "chat-stream"
        },
        // onOpen: () => console.log('opened qPilotWs'),
        shouldReconnect: (closeEvent) => {
            // alert("qPilotWs closeEvent", closeEvent)
            return true
        },

        onMessage: (rawMessage) => {
            const message = JSON.parse(rawMessage.data)

            // ONLY USE IN DEBUG MODE
            // if (message['other_data'] && Object.keys(message['other_data']).length > 0 && message['other_data']['db_entries']) {
            //     // CATCH DB ENTRIES AND PASS TO PARENT COMPONENT
            //     props.handleMessageContext(message['other_data']['db_entries'])
            // }
            // if (message && messageHistory.length === 1 && Object.keys(message).length > 0) {
            //     setAiMessage((prev) => prev.replace("Activating QPilot", "Launching QPilot..."));
            // }
            if (message && messageHistory.length === 1 && Object.keys(message).length > 0 && message.message && message.message.length > 0  && message.message.indexOf("Starting agent:") > -1 && aiMessage.indexOf("Launching QPilot") < 0) {
                setAiMessage((prev) => prev + "Launching QPilot...");
            } else if (message && Object.keys(message).length > 0 && message.message && message.message.length > 0 && message.message.indexOf("Activating QPilot") > -1) {
                console.log("message", message)
                console.log("message aiMessage", aiMessage)
                setAiMessage("Launching QPilot...");
            } else if (message && Object.keys(message).length > 0 && message.message && message.message.length > 0 &&  message.message.indexOf("Starting tool:") > -1 && aiMessage.indexOf("Analyzing...") < 0) {
                setAiMessage((prev) => prev.replace("Launching QPilot...", " ") + "Analyzing...");
            } else if (message && Object.keys(message).length > 0 && message.message && message.message.length > 0 && message.message.indexOf("Done tool:") > -1) {
                setAiMessage((prev) => prev.replace("Analyzing...", " ").replace("Launching QPilot...", " "));
            } else if (message && Object.keys(message).length > 0 && message.message && message.message.length > 0 &&  (message.message.indexOf("Starting tool:") > -1 || message.message.indexOf("Starting agent:") > -1 || message.message.indexOf("Done tool:") > -1 || message.message.indexOf("Tool output was:") > -1)) {
                return
            } else {
                // console.log("onMessage message", message)
                setAiMessage((prev) => prev + message.message);
            }

            if (message && Object.keys(message).length > 0)
                if (message.message && message.message.length > 0 && message.message.indexOf("Done agent:") > -1) {
                    setAiMessage("")
                    setMessageHistory((prev) => prev.concat({
                        ...message,
                        message: message.message && message.message.length > 0 && message.message.replace("Done agent: AgentExecutor with output: ", "")
                    }));
                }

        }


    });

    // const session = await Auth.currentSession();
    // const token = session.getAccessToken().getJwtToken();

    // Run when the connection state (readyState) changes
    useEffect(() => {
        const getTokenAndSubscribe = async () => {
            try {
                const session = await Auth.currentSession();
                const token = session.getAccessToken().getJwtToken();

                sendJsonMessage({
                    event: "subscribe",
                    jwt: token,
                    data: {
                        channel: "chat-stream",
                    },
                    keep: true
                });
            } catch (error) {
                console.error("Error retrieving JWT token:", error);
                // Optionally handle authentication errors (e.g., redirect to login)
            }
        };
        getTokenAndSubscribe();
    }, [readyState])

// // Try to reconnect if the user's auth token changes
//     const previousAuthToken = usePreviousValue(currUser.authToken)
//
//     useEffect(() => {
//         if (readyState === ReadyState.OPEN && previousAuthToken !== currUser.authToken) {
//             // alert(`Alert - auth token changed \n ${previousAuthToken} \n vs \n ${currUser.authToken}`)
//             sendJsonMessage({
//                 event: "subscribe",
//                 jwt: currUser.authToken,
//                 data: {
//                     channel: "chat-stream",
//                 },
//             })
//         }
//     }, [currUser.authToken])
//
//     const clearMessageHistory = () => {
//         setMessageHistory([])
//     }


    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];


    const prebuiltQuestions = [
        "What marketing programs are driving pipeline?",
        "What was ARR last quarter?",
        "What was my new business win rate last quarter?",
        // "What are predicted bookings for the current quarter?"
    ]

    const [activePrebuiltQuestions, setActivePrebuiltQuestions] = React.useState(prebuiltQuestions)

    const handleAdoptQuestion = async (question) => {
        const session = await Auth.currentSession();
        const token = session.getAccessToken().getJwtToken();
        const outgoingMessage = {
            sender: {
                sender_id: currUser.id, sender_name: `${currUser.firstName} ${currUser.lastName}`
            }, message: question,
            jwt: token,
            channel: "chat-stream",
            messageHistoryLength: !messageHistory ? 0 : messageHistory.length
        }
        setActivePrebuiltQuestions((prev) => prev.filter(item => item !== question))
        sendJsonMessage({...outgoingMessage}, true)
        setMessageHistory((prev) => prev.concat({...outgoingMessage}));
        setCommandKeyDown(false)
        setCurrentPrompt("")
    }


    const handleClearConversation = async () => {
        const session = await Auth.currentSession();
        const token = session.getAccessToken().getJwtToken();
        const outgoingMessage = {
            sender: {
                sender_id: currUser.id, sender_name: `${currUser.firstName} ${currUser.lastName}`
            }, message: "[CLEAR_CONVERSATION]",
            jwt: token

        }
        sendJsonMessage({...outgoingMessage}, true)
        setMessageHistory([]);
        setCurrentPrompt("")
        setAiMessage("")
    }

    const handleClickSendMessage = useCallback(() => {
        const getJwtAndSend = async () => {
            const session = await Auth.currentSession();
            const token = session.getAccessToken().getJwtToken();
            const outgoingMessage = {
                sender: {
                    sender_id: currUser.id, sender_name: `${currUser.firstName} ${currUser.lastName}`
                },
                message: currentPrompt,
                channel: "chat-stream",
                messageHistoryLength: !messageHistory ? 0 : messageHistory.length,
                jwt: token
            }
            sendJsonMessage({...outgoingMessage}, true)
        setMessageHistory((prev) => prev.concat({...outgoingMessage}));
        }
        if (readyState === ReadyState.OPEN) {
            getJwtAndSend()
        } else {
            console.log("WebSocket connection is not open. Please wait and try again.")
        }
        setCommandKeyDown(false)
        setCurrentPrompt("")
    }, [currentPrompt]);


    // WEBSOCKET LOGIC END


    // console.log("qPilotWs messageHistory", messageHistory)


    return (

        <Drawer
            open={true}
            variant={"permanent"}
            anchor={"right"}
            sx={{
                zIndex: 1199,
                width: props.qPilotDrawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    backgroundColor: "rgb(249, 249, 249)",
                    width: props.qPilotDrawerWidth,
                    boxSizing: "border-box"
                }
            }}
        >
            <Box sx={{pt: "70px", height: props.isMobile ? `30vh` : `calc(100vh - 70px)`}}>
                {<Tooltip placement="left" title={<span
                    style={{fontSize: "1.35em"}}>{!props.showQPilotChat ? "Show QPilot" : "Hide QPilot"}</span>}>
                    <ToggleButton
                        size={"small"}
                        id={"qpilot_chatbot_toggle"}
                        value={"open"}
                        sx={{padding: 0}}
                        onClick={props.handleShowQPilotChatChange}>
                        {!props.showQPilotChat ? <Avatar
                                variant={"rounded"}
                                size={"small"}
                                src={QBearMascot}
                                sx={{color: "#1de9b6", backgroundColor: "#272931", width: 28, height: 28}}
                            />
                            :
                            <Box sx={{
                                color: "#fff",
                                backgroundColor: "#272931",
                                width: 28,
                                height: 28,
                                pt: "4px",
                                borderRadius: "3px",
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                '&:hover': {
                                    backgroundColor: "#333",
                                    color: "#999"
                                }
                            }}>
                                <AiOutlineCaretRight/>
                            </Box>}
                    </ToggleButton>
                </Tooltip>
                }
                {props.showQPilotChat &&
                    <Box sx={{width: "100%", height: "65vh"}}>
                        {props.isInitializing && <Grid container spacing={3} alignItems={"center"}
                                                       justifyContent={"center"}><Grid item xs={12} lg={12}>
                            <Typography sx={{textAlign: "center"}}>
                                Initializing QPilot...
                            </Typography>
                        </Grid></Grid>}

                        <Alert
                            icon={false}
                            sx={{".MuiAlert-message": {minWidth: "100%"}}}
                            style={{
                                backgroundColor: "rgb(249, 249, 249)",
                                color: "#000000",
                                // whiteSpace: 'pre-line',
                                // height: props.height * 0.5,
                                height: "100%",
                                width: "100%",
                                maxHeight: "60vh",
                                padding: "0px",
                                flexShrink: 0,
                            }}
                            severity={"success"}>
                            <div
                                style={{
                                    width: "100%",
                                    overflowY: "auto",
                                    // flexDirection: "column",
                                    // display: "flex",
                                }}>
                                <List sx={{width: '100%'}}>
                                    {messageHistory.map((item, i) =>
                                        <ListItem key={"chat" + i} style={{
                                            width: "100%",
                                            display: "inline-flex",
                                            justifyContent: item.sender['sender_id'] === "qpilot" ? "flex-start" : "flex-end",
                                            alignItems: item.sender['sender_id'] === "qpilot" ? "flex-start" : "flex-end",
                                        }}>
                                            {/*        <Grid container*/}
                                            {/*              justifyContent={item.sender['sender_id'] === "qpilot" ? "flex-start" : "flex-end"}*/}
                                            {/*         style={{*/}
                                            {/*    textAlign: item.sender['sender_id'] === "qpilot" ? "left" : "right",*/}
                                            {/*    width: "100%",*/}
                                            {/*}}>*/}
                                            <Stack spacing={1}
                                                   direction={item.sender['sender_id'] === "qpilot" ? "row" : "row-reverse"}>
                                                {item.sender['sender_id'] === "qpilot" &&
                                                    <ListItemAvatar sx={{p: 0, m: 0, minWidth: 34}}>
                                                        <Avatar
                                                            variant={"rounded"}
                                                            size={"small"}
                                                            src={item.sender['sender_id'] === "qpilot" ? QBearMascot : undefined}
                                                            sx={{
                                                                color: "#FFFFFF",
                                                                backgroundColor: "#272931",
                                                                width: 28,
                                                                height: 28,
                                                            }}
                                                        >{props.currUser.firstName.slice(0, 1) + props.currUser.lastName.slice(0, 1)}</Avatar>
                                                    </ListItemAvatar>
                                                }
                                                <div style={{
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: "0.5em",
                                                    paddingRight: "0.5em",
                                                    width: "100%",
                                                    border: item.sender['sender_id'] === "qpilot" ? "0px #b6b6b6 solid" : "1px #4DA685 solid",
                                                    borderRadius: "3px",
                                                    color: item.sender['sender_id'] === "qpilot" ? undefined : "#FFFFFF",
                                                    backgroundColor: item.sender['sender_id'] === "qpilot" ? undefined : "#4DA685"
                                                }}><Markdown
                                                    remarkPlugins={[[remarkMath, remarkMathOptions]]}
                                                    rehypePlugins={[rehypeRaw, rehypeKatex]}
                                                    className={"QPilotStyles"}
                                                >{item.message}</Markdown>
                                                </div>
                                            </Stack>
                                            {/*</Grid>*/}
                                        </ListItem>
                                    )}
                                </List>

                                {aiMessage &&
                                    <ListItem key={"incoming_message"} sx={{alignItems: "flex-start"}}>
                                        <Stack spacing={1} direction={"row"}>
                                            <ListItemAvatar sx={{p: 0, m: 0, minWidth: 34}}>
                                                <Avatar
                                                    variant={"rounded"}
                                                    size={"small"}
                                                    src={QBearMascot}
                                                    sx={{
                                                        color: "#1de9b6",
                                                        backgroundColor: "#272931",
                                                        width: 28,
                                                        height: 28,
                                                    }}
                                                />
                                            </ListItemAvatar>

                                            <div style={{
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                paddingLeft: "0.5em",
                                                paddingRight: "0.5em",
                                                width: "100%",
                                                border: "0px #b6b6b6 solid",
                                                borderRadius: "3px",
                                                color: undefined,
                                                backgroundColor: undefined
                                            }}><Markdown
                                                remarkPlugins={[[remarkMath, remarkMathOptions]]}
                                                rehypePlugins={[rehypeRaw, rehypeKatex]}
                                                className={"QPilotStyles"}
                                            >{aiMessage}</Markdown>
                                            </div>
                                        </Stack>
                                    </ListItem>}
                                <div ref={messagesEndRef}/>
                            </div>


                            {/*{props.isRunningQPilot &&*/}
                            {/*    <ListItem key={"chat-incoming"}><ListItemAvatar*/}
                            {/*        sx={{p: 0, m: 0, minWidth: 34}}>*/}
                            {/*        <Avatar*/}
                            {/*            variant={"rounded"}*/}
                            {/*            size={"small"}*/}
                            {/*            src={QBearMascot}*/}
                            {/*            sx={{*/}
                            {/*                color: "#1de9b6",*/}
                            {/*                backgroundColor: "#272931",*/}
                            {/*                width: 28,*/}
                            {/*                height: 28*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    </ListItemAvatar>*/}

                            {/*        <div style={{height: 36, paddingTop: 4}}*/}
                            {/*        >*/}
                            {/*            Thinking...*/}
                            {/*        </div>*/}
                            {/*    </ListItem>*/}
                            {/*}*/}
                        </Alert>
                        <Box sx={{
                            position: "sticky",
                            bottom: 0,
                            backgroundColor: "rgb(249, 249, 249)",
                            paddingTop: "1em",
                            paddingBottom: "1em",
                            paddingLeft: "0.75em",
                            paddingRight: "0.75em",
                        }}>
                            <Grid container pt={2} justfifyContent={"center"}>

                                <Grid item xs={12}>
                                    <Grid container sx={{borderTop: "1px dotted", pt: 0.5, pb: 0.5}}>
                                        {activePrebuiltQuestions.map((item) => <Chip sx={{m: 0.5, cursor: "pointer"}}
                                                                               key={item} label={item}
                                                                               onClick={() => handleAdoptQuestion(item)}/>)}
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField sx={{fontSize: 12}} fullWidth
                                               onChange={handleCurrentPrompt}
                                               id={"qpilot_active_chat"}
                                               size={"small"}
                                               maxRows={3}
                                               rows={3}
                                               autoFocus={true}
                                               multiline
                                               placeholder={"Message QPilot..."}
                                               value={currentPrompt}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant={"caption"}>{operatingSystem === "Mac OS" ? "command + Enter to send" : operatingSystem === "Windows" ? "CTRL + Enter to send" : null} </Typography>
                                </Grid>

                                <Grid container spacing={1} alignItems={"space-between"}
                                      sx={{pt: "4px"}}>
                                    <Grid item pt={1} pb={1}>
                                        <Button onClick={handleClickSendMessage}
                                                disabled={!currentPrompt}
                                                id={"qpilot_send_message"}
                                                variant={"contained"}
                                        >
                                            Send</Button>
                                    </Grid>
                                    <Grid item pt={1} pb={1}>
                                        <Button onClick={handleClearConversation}
                                                disabled={messageHistory.length === 0}
                                                variant={"outlined"}
                                        >
                                            Clear</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*Connection Status: {connectionStatus*/}
                        </Box>
                    </Box>
                }
            </Box>
        </Drawer>
    )
}