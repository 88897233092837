import React from "react";
import Container from "@mui/material/Container";
import {Link as RouterLink} from "react-router-dom";
import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {QFlowLogoSVG} from "../components/img/QFlowLogoSVG";
import Divider from "@mui/material/Divider";
import {Typography} from "@mui/material";


const Soc2Logo = "/images/qflow-is-soc2-certified_Blk.png"
export default function UnauthenticatedFooter() {

    return (
        <div style={{
            // position: 'absolute',
            bottom: 0, width: "100%",
            backgroundColor: '#fff'
        }}>
            <Container sx={{
                fontSize: "14px",
                paddingBottom: "5em",
                width: '100vw',
                paddingTop: 'calc(1% + 60px)',
                backgroundColor: '#fff',
                border: 0,
                color: "primary",
                flexGrow: 1,
                maxWidth: 'xl',
                // component:'div',
                // maxWidth:'auto',
                // backgroundImage: {HomepageHeroSVG},

                // backgroundImage: '#272931',
                fontFamily: [
                    'Assistant, sans-serif',
                    '-apple-system',
                    'BlinkMacSystemFont',
                    '"Segoe UI"',
                    'Helvetica Neue',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                ].join(','),
            }}>
                <Divider
                    sx={{
                        backgroundColor: "primary"
                    }}
                    variant="fullWidth"/>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Grid container direction="column" spacing={1} alignItems="flex-start">
                            <Grid item><Box paddingTop="3.35em"/></Grid>
                            <Grid item>
                                <QFlowLogoSVG width='7em' color='#272931'/>
                            </Grid>
                            <Grid item>
                                &copy; QFlow.ai,
                                Inc.&trade; {new Date().toLocaleDateString(undefined, {year: "numeric"})}
                            </Grid>

                            <Grid item>
                                San Francisco, California
                            </Grid>

                            <Grid item>
                               <Link href={"https://trust.qflow.ai"}> <img alt="QFlow is SOC 2 Certified" src={Soc2Logo} width={145}/></Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Grid container direction="column" spacing={2} alignItems="flex-start">
                            <Grid item>
                                <Box paddingTop="3.35em"/>
                            </Grid>
                            <Grid item>
                                <Typography
                                    style={{fontSize: 'inherit', textTransform: 'uppercase'}}>Product</Typography>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      to="/products/go-to-market-stats">
                                    Go-to-Market Stats
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      to="/products/multi-quarter-projections">
                                    Multi-quarter Revenue Projections
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}} to="/products/forecasting">
                                    In-quarter Sales Forecasting
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      to="/products/machine-learning">
                                    QPilot (AI Analyst)
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      to="/products/business-digest">
                                    Business Digest
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      to="/products/account-based-attribution">
                                    Multi-touch Attribution
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      to="/products/campaign-measurement">
                                    Campaign Effectiveness
                                </RouterLink>
                            </Grid>

                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      to="/products/inbound-lead-response-measurement">
                                    Inbound Response Measurement
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      to="/products/sales-activity">
                                    Sales Activity Analytics
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      to="/products/rep-ramp">
                                    Rep Ramp Management
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      to="/products/sales-pipeline-analytics">
                                    Pipeline Forensics
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}} to="/products/integrations">
                                    QFlow Connect
                                </RouterLink>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Grid container direction="column" spacing={2} alignItems="flex-start">
                            <Grid item>
                                <Box paddingTop="3.35em"/>
                            </Grid>
                            <Grid item>
                                <Typography
                                    style={{fontSize: 'inherit', textTransform: 'uppercase'}}>Company</Typography>
                            </Grid>
                            {/*<Grid item>*/}
                            {/*    About*/}
                            {/*</Grid>*/}
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      to="/about">
                                    About
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <Link color="primary" style={{textDecoration: "none"}}
                                      href="https://blog.qflow.ai">
                                    Blog
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link color="primary" style={{textDecoration: "none"}}
                                      to="/partners">
                                    Partners
                                </Link>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      href={`/careers`}>
                                    Careers
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      href={`/faq`}>

                                    Support
                                </RouterLink>
                            </Grid>
                             <Grid item>
                                <Link color="primary" style={{textDecoration: "none"}}
                                      href={`https://status.qflow.ai`}>
                                    Service Status
                                </Link>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}} to="/pricing">
                                    Pricing
                                </RouterLink>
                            </Grid>
                            <Grid item>&nbsp;</Grid>
                            <Grid item>
                                <Typography style={{fontSize: 'inherit', textTransform: 'uppercase'}}>QFlow
                                    Benefits</Typography>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      to="/solutions/finance">
                                    For Finance Teams
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}}
                                      to="/solutions/revenue-operations">
                                    For Revenue Operations
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}} to="/solutions/sales">
                                    For Sales
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}} to="/solutions/marketing">
                                    For Marketing
                                </RouterLink>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Grid container direction="column" spacing={2} alignItems="flex-start">
                            <Grid item>
                                <Box paddingTop="3.35em"/>
                            </Grid>
                            <Grid item>
                                <Typography style={{fontSize: 'inherit', textTransform: 'uppercase'}}>Legal &
                                    Security</Typography>
                            </Grid>
                                 <Grid item>
                                <Link color="primary" style={{textDecoration: "none"}} href="https://trust.qflow.ai">
                                    Trust Portal
                                </Link>
                            </Grid>
                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}} to="/service-terms">
                                    Service Terms & Privacy Policy
                                </RouterLink>
                            </Grid>

                            <Grid item>
                                <RouterLink color="primary" style={{textDecoration: "none"}} to="/security">
                                    Security, Privacy, and Architecture
                                </RouterLink>
                            </Grid>
                            <Grid item>&nbsp;</Grid>
                            <Grid item>
                                <Typography
                                    style={{fontSize: 'inherit', textTransform: 'uppercase'}}>Contact</Typography>
                            </Grid>
                            <Grid item>
                                <RouterLink sx={{pointer: "cursor"}} color="primary" style={{textDecoration: "none"}} data-cal-link="team/qflow/30-min-call" data-cal-config='{"layout":"month_view"}' data-cal-namespace="">
                                    Book a Demo
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <Link color="primary" style={{textDecoration: "none"}} href="tel:+14157356910">
                                    +1 (415) 735-6910
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/*<CookieConsent*/}
                {/*      expires={10}*/}
                {/*    style={{backgroundColor: '#fff', color: '#4f515b'}}*/}
                {/*buttonStyle={{backgroundColor: '#579FEE', color: '#fff', borderRadius: '2px'}}*/}
                {/*>This website uses cookies to enhance user experience. By continuing to use this website, you agree to their use.</CookieConsent>*/}

            </Container>
        </div>

    )
}