import apiClient from "./apiClient";
import {config} from "../config";
import {useQuery} from "@tanstack/react-query";
import {useAppContext} from "../libs/appContextLib";


export const useFetchAccountSearch = ({debouncedInputValue}) => {
    const {currUser, dataStatus} = useAppContext();
     return useQuery({
         queryKey: [`${currUser.customerId}_accounts`, debouncedInputValue],
         queryFn: async () => {
                 const response = await apiClient.get(
                     `${config.REACT_APP_BACKEND_HOST}/accounts-list`,
                     {params: {'search_string': debouncedInputValue && debouncedInputValue.length > 0 ? debouncedInputValue : undefined}})
                 return response.data
         },
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         staleTime: 1000 * 60 * 60 * 36, // or a high value if you want some automatic refetch
         cacheTime: 1000 * 60 * 60 * 36, // 36 hour -- or until invalidated
         retry: false
     })
};
