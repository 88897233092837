import React, {useEffect} from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import {ListItemButton, Stack, Tooltip} from '@mui/material';
import {ClosedWonFlowsIcon} from '../../components/Icons/ClosedWonFlowsIcon';
import {OpportunityCreationIcon} from '../../components/Icons/OpportunityCreationIcon';
import {PipelineFlowsIcon} from '../../components/Icons/PipelineFlowsIcon';
import {NodeEffectivenessIcon} from '../../components/Icons/NodeEffectivenessIcon';
import {PCloseIcon} from '../../components/Icons/PCloseIcon';
import {ActivityHeatmapIcon} from '../../components/Icons/ActivityHeatmapIcon';
import {ConfigIcon} from '../../components/Icons/ConfigIcon';
import '../../components/Icons/IconStyles.css';
// import MenuItem from "@mui/material/MenuItem";
import {useAppContext} from "../../libs/appContextLib";
import {TreeView} from '@mui/x-tree-view/TreeView';
import {treeItemClasses} from '@mui/x-tree-view/TreeItem';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from "@mui/material/Typography";
import {generatePath, useHistory, useLocation} from "react-router";
import {InboundFollowupIcon} from "../../components/Icons/InboundFollowupIcon";
import {SalesRampIcon} from "../../components/Icons/SalesRampIcon";
import {PipelineAnalyticsIcon} from "../../components/Icons/PipelineAnalyticsIcon";
import {BookingTrendsIcon} from "../../components/Icons/BookingTrendsIcon";
import Grow from "@mui/material/Grow";
import NotSetupSnackBar from "./NotSetupSnackbar";
import Box from "@mui/material/Box";
import {ForecastIcon} from "../../components/Icons/ForecastIcon";
import {DashboardIcon} from '../../components/Icons/DashboardIcon';
import {DigestIcon} from '../../components/Icons/DigestIcon';
import {GtmStatsIcon} from "../../components/Icons/GtmStatsIcon";
import {styled} from "@mui/material/styles";
import {PConvertIcon} from "../../components/Icons/PConvertIcon";
import RolePermissions from "../../RolePermissions";
import {BoardStatsIcon} from "../../components/Icons/BoardStatsIcon";
import {CustomerInvoicesIcon} from "../../components/Icons/CustomerInvoicesIcon";
import get from "lodash/get";
import {PromptsIcon} from "../../components/Icons/PromptsIcon";
import {DepartmentSpendIcon} from "../../components/Icons/DepartmentSpendIcon";
import {ArrScheduleIcon} from "../../components/Icons/ArrScheduleIcon";
import AccountSearch from "../Accounts/AccountSearch";
import {AccountManagementIcon} from "../../components/Icons/AccountManagementIcon";
import QNavMenuItem from "./QNavMenuItem";


const StyledTreeItemRoot = styled(TreeItem)(({theme}) => ({
    color: theme.palette.primary.main,
    [`& .${treeItemClasses.content}`]: {
        // borderTopRightRadius: theme.spacing(2),
        // borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            // backgroundColor: "#393A42",
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            // backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            // color: 'var(--tree-view-color)',
            // color: theme.palette.secondaryNew.contrastText,
            color: theme.palette.secondaryNew.main,
            fontWeight: "bold"
            // backgroundColor: theme.palette.secondaryNew.main,
            // color: '#fff',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

function StyledTreeItem(props) {
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        baseItem,
        ...other
    } = props;

    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 0.5,
                    pr: 0,
                    pl: baseItem ? 1.9 : undefined,
                    ml: baseItem ? "-3px" : undefined
                }}>
                    <Box component={LabelIcon} color="inherit" sx={{mr: 1, ml: baseItem ? -1.75 : undefined}}/>
                    <Typography variant="body2" sx={{fontWeight: 'inherit', flexGrow: 1}}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
}



function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
                d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z"/>
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
                d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z"/>
        </SvgIcon>
    );
}


// const QNavMenuItem = ({children, ...menuItemProps}) => {
//     return (
//         <MenuItem
//             {...menuItemProps}
//             sx={{
//                 backgroundColor: "#FFF",
//                 // paddingLeft: "22.5px",
//                 // display: "flex",
//                 // textAlign: "left",
//
//                 '& .MuiListItemIcon-root': {
//                     color: "#272931",
//                     margin: 0,
//                     '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused':
//                         {color: "#4DA685",},
//                 },
//
//                 '& .MuiListItemButton-root': {
//                     backgroundColor: "transparent",
//                     '&:hover': {backgroundColor: "transparent"}
//                 },
//             }}
//         >
//             {children}
//         </MenuItem>
//     );
// };

const QNavListItems = (props) => {
    const {dataSetupComplete, currUser, subscriptionStatus} = useAppContext();
    const location = useLocation();
    const history = useHistory();

    const excludeDataGenTrialEnded = get(subscriptionStatus, "exclude_data_gen", false) && currUser.customerId !== "2a2b4a38-4635-47d4-9a36-3736a1d051c5"


    // const [selected, setSelected] = React.useState([]);
    const [expanded, setExpanded] = React.useState(localStorage.getItem("qNavBarExpanded") ? localStorage.getItem("qNavBarExpanded").split(",")
        : [-1, 0, 1, 2]);

    const selected = props.pathName


    // useEffect(() => {
    //     const pathName = location.pathname.indexOf("/", 1) > -1 ? location.pathname.slice(0, location.pathname.indexOf("/", 1)) : location.pathname
    //     setSelected(pathName)
    // }, [location])


    const currUserPermissions = RolePermissions

    useEffect(() => {
        localStorage.setItem("qNavBarExpanded", expanded)
    }, [expanded])

    const [snackbarState, setSnackbarState] = React.useState({
        open: false,
        Transition: Grow,
    });


    const handleClose = () => {
        setSnackbarState({open: false});
    }
    const handleNodeToggle = (e, val) => {
        setExpanded(val)

    }

    const handleNavClick = (e, id) => {

// id && commandKeyDown ?
//             window.open(`${id}`, "_blank", 'noopener,noreferrer')
//             :  history.push(`${id}`)


        e.preventDefault()
        if (!dataSetupComplete && !excludeDataGenTrialEnded) {
            setSnackbarState({open: true})
        }


        if (e.ctrlKey || e.metaKey) {
            window.open(`${id}`, "_blank", 'noopener,noreferrer')
            // const newWindow = window.open(`/${id}`, '_blank', 'noopener,noreferrer')
            // if (newWindow) {
            //     newWindow.opener = null
            // }

        } else if (e.type === 'click') {
            history.push(
                generatePath(id)
            )
        }
    }


    const {dataStatus} = useAppContext();
    const acceptedBookingMetric = dataStatus && dataStatus['booking_metric']
    // const acceptedBookingMetricString = acceptedBookingMetric === "amount_field" ? "Amount" : "ARR";

    return (
        //show icon-only version when props.drawerOpen === false, otherwise show tree view
        <div>
            {currUser && props.pathName && props.drawerOpen ?
                <Stack direction={"column"} spacing={5} alignItems={"space-between"}  justifyContent={"space-between"}>
                        <TreeView
                            style={{paddingTop: '2em'}}
                            expanded={expanded}
                            defaultCollapseIcon={<MinusSquare/>}
                            defaultExpandIcon={<PlusSquare/>}
                            selected={selected}
                            onNodeToggle={(e, val) => handleNodeToggle(e, val)}
                        >
                            <StyledTreeItem
                                labelText="Business Digest"
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["business-digest"].hasAccess !== true}
                                nodeId={"/business-digest"}
                                labelIcon={DigestIcon}
                                baseItem
                                selected={selected.includes("/business-digest")}
                                onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/business-digest") : setSnackbarState({open: true})}
                            />


                            <StyledTreeItem nodeId="9" labelText="Predict"
                            >
                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["scenarios"].hasAccess !== true}
                                    nodeId="/scenarios"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/scenarios") : setSnackbarState({open: true})}
                                    labelText="Scenarios"
                                    labelIcon={DashboardIcon}
                                />

                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["forecast"].hasAccess !== true}
                                    nodeId="/forecast"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/forecast") : setSnackbarState({open: true})}
                                    labelText="Forecast"
                                    labelIcon={ForecastIcon}
                                />
                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["pclose"].hasAccess !== true}
                                    nodeId="/pclose"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/pclose") : setSnackbarState({open: true})}
                                    labelText="P(Close)"
                                    labelIcon={PCloseIcon}
                                />
                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["pconvert"].hasAccess !== true}
                                    nodeId="/pconvert"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/pconvert") : setSnackbarState({open: true})}
                                    labelText="P(Convert)"
                                    labelIcon={PConvertIcon}
                                />
                                <Box pt={3}/>
                            </StyledTreeItem>

                            <React.Fragment>
                                <StyledTreeItem nodeId="99" labelText="Stats"
                                    // labelIcon={Label}
                                >
                                    <StyledTreeItem labelText="Board Stats"
                                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["board-stats"].hasAccess !== true}
                                                    labelIcon={BoardStatsIcon}
                                                    nodeId={"/board-stats"}
                                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded
                                                        ? handleNavClick(e, "/board-stats") : setSnackbarState({open: true})}
                                    />
                                    <StyledTreeItem labelText="GTM Stats"
                                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["gtm-stats"].hasAccess !== true}

                                                    labelIcon={GtmStatsIcon}
                                                    nodeId={"/gtm-stats"}
                                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/gtm-stats") : setSnackbarState({open: true})}
                                    />
                                    <StyledTreeItem
                                        // sx={{marginLeft: -2.25}}
                                        disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["opp-creation"].hasAccess !== true}
                                        nodeId={"/opp-creation"}
                                        labelIcon={OpportunityCreationIcon}
                                        labelText="Opp Creation"
                                        onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/opp-creation") : setSnackbarState({open: true})}
                                    />
                                    <StyledTreeItem
                                        // sx={{marginLeft: -2.25}}
                                        disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["win-rate"].hasAccess !== true}
                                        nodeId="/win-rate"
                                        onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/win-rate") : setSnackbarState({open: true})}
                                        labelText="Win Rates"
                                        labelIcon={BookingTrendsIcon}
                                    />
                                    <Box pt={3}/>
                                </StyledTreeItem>
                            </React.Fragment>

                            <StyledTreeItem nodeId="1" labelText="Sales Drivers">
                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["inbound-follow-up"].hasAccess !== true}
                                    nodeId="/inbound-follow-up"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/inbound-follow-up") : setSnackbarState({open: true})}
                                    labelText="Inbound Follow-Up"
                                    labelIcon={InboundFollowupIcon}
                                />
                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["gtm-activity"].hasAccess !== true}
                                    nodeId="/gtm-activity"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/gtm-activity") : setSnackbarState({open: true})}
                                    labelText="GTM Activity"
                                    labelIcon={ActivityHeatmapIcon}
                                />

                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["rep-ramp"].hasAccess !== true}
                                    nodeId="/rep-ramp"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/rep-ramp") : setSnackbarState({open: true})}
                                    labelText="Rep Ramp"
                                    labelIcon={SalesRampIcon}
                                />


                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["pipeline-forensics"].hasAccess !== true}
                                    nodeId="/pipeline-forensics"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/pipeline-forensics") : setSnackbarState({open: true})}
                                    labelText="Pipeline Forensics"
                                    labelIcon={PipelineAnalyticsIcon}
                                />


                                <Box pt={3}/>
                            </StyledTreeItem>
                            {/*<StyledTreeItem nodeId="2" labelText="ML Recipes" labelIcon={Label}>*/}
                            {/*    <StyledTreeItem*/}
                            {/*        disabled={excludeDataGenTrialEnded || !dataSetupComplete}*/}
                            {/*        nodeId="/pconvert"*/}
                            {/*        onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/pconvert") : setSnackbarState({open: true})}*/}
                            {/*        labelText="P(Convert)"*/}
                            {/*        labelIcon={PConvertIcon}*/}
                            {/*    />*/}
                            {/*    <StyledTreeItem*/}
                            {/*        disabled={excludeDataGenTrialEnded || !dataSetupComplete}*/}
                            {/*        nodeId="/pclose"*/}
                            {/*        onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/pclose") : setSnackbarState({open: true})}*/}
                            {/*        labelText="P(Close)"*/}
                            {/*        labelIcon={PCloseIcon}*/}
                            {/*    />*/}
                            {/*</StyledTreeItem>*/}
                            <StyledTreeItem nodeId="0" labelText="Marketing Drivers">
                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["program-effectiveness"].hasAccess !== true}
                                    nodeId="/program-effectiveness"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/program-effectiveness") : setSnackbarState({open: true})}
                                    labelText="Program Effectiveness"
                                    labelIcon={NodeEffectivenessIcon}
                                />
                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["winflows"].hasAccess !== true}
                                    nodeId="/winflows"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/winflows") : setSnackbarState({open: true})}
                                    labelText="Win Attribution"
                                    labelIcon={ClosedWonFlowsIcon}
                                />


                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["pipelineflows"].hasAccess !== true}
                                    nodeId="/pipelineflows"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/pipelineflows") : setSnackbarState({open: true})}
                                    labelText="Pipeline Attribution"
                                    labelIcon={PipelineFlowsIcon}
                                />

                                {/*<StyledTreeItem*/}
                                {/*    // sx={{marginLeft: -2.25}}*/}
                                {/*    disabled={excludeDataGenTrialEnded || !dataSetupComplete}*/}
                                {/*    nodeId="/dealexplorer"*/}
                                {/*    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/dealexplorer") : setSnackbarState({open: true})}*/}
                                {/*    labelText="Segment Explorer"*/}
                                {/*    labelIcon={DealBreakdownIcon}*/}
                                {/*/>*/}

                                <Box pt={3}/>
                            </StyledTreeItem>


                            <StyledTreeItem nodeId="77" labelText="Workflows">


                                { process.env.REACT_APP_STAGE === 'dev' &&
                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["config"].hasAccess !== true}
                                    nodeId="/account-management"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/account-management") : setSnackbarState({open: true})}
                                    labelText="Account Management"
                                    labelIcon={AccountManagementIcon}
                                />
                                }

                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["config"].hasAccess !== true}
                                    nodeId="/config"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/config/programs") : setSnackbarState({open: true})}
                                    labelText="Campaign Spend"
                                    labelIcon={ConfigIcon}
                                />
                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["customer-invoices"].hasAccess !== true}
                                    nodeId="/customer-invoices"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/customer-invoices") : setSnackbarState({open: true})}
                                    labelText="Customer Invoices"
                                    labelIcon={CustomerInvoicesIcon}
                                />

                                {acceptedBookingMetric !== "amount_field" && process.env.REACT_APP_STAGE === 'dev' &&
                                    <StyledTreeItem
                                        // sx={{marginLeft: -2.25}}
                                        disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["arr-schedule"].hasAccess !== true}
                                        nodeId="/arr-schedule"
                                        onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/arr-schedule") : setSnackbarState({open: true})}
                                        labelText="ARR Schedule"
                                        labelIcon={ArrScheduleIcon}
                                    />
                                }

                                <StyledTreeItem
                                    // sx={{marginLeft: -2.25}}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["department-spend"].hasAccess !== true}
                                    nodeId="/department-spend"
                                    onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/department-spend") : setSnackbarState({open: true})}
                                    labelText="Department Spend"
                                    labelIcon={DepartmentSpendIcon}
                                />


                                {(currUser.email.indexOf("@qflow.ai") > -1 || currUser.email.indexOf("blossom") > -1 || currUser.email.indexOf("arosten") > -1
                                    ) &&
                                    <StyledTreeItem
                                        // sx={{marginLeft: -2.25}}
                                        disabled={currUser.email.indexOf("@qflow.ai") === -1}
                                        nodeId="/manage-prompts"
                                        onClick={(e) => dataSetupComplete && !excludeDataGenTrialEnded ? handleNavClick(e, "/manage-prompts") : setSnackbarState({open: true})}
                                        labelText="Prompts"
                                        labelIcon={PromptsIcon}
                                    />
                                }


                            </StyledTreeItem>


                        </TreeView>
                    <AccountSearch drawerWidth={props.drawerWidth}/>
                </Stack>
                :
                <Box sx={{pt: 2}}>


                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Business Digest" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                to={"/business-digest"}
                                selected={selected.includes("/business-digest")}
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["business-digest"].hasAccess !== true}
                            >
                                <ListItemButton dense disableGutters component="a"
                                >
                                    <ListItemIcon>
                                        <DigestIcon
                                            color={selected.includes("/business-digest") ? "#4DA685" : "#272931"}
                                        />
                                    </ListItemIcon>
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>


                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Scenarios" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                to="/scenarios"
                                selected={selected.includes("/scenarios")}
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["scenarios"].hasAccess !== true}
                            >
                                <ListItemButton dense disableGutters component="a"
                                >
                                    <ListItemIcon>
                                        <DashboardIcon
                                            color={selected.includes("/scenarios") ? "#4DA685" : "#272931"}
                                            // color={selected.includes("/scenarios") ? "secondaryNew" : undefined}
                                        />
                                    </ListItemIcon>
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>

                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Forecast" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                to="/forecast"
                                selected={selected.includes("/forecast")}
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["forecast"].hasAccess !== true}
                            >
                                <ListItemButton dense disableGutters component="a">
                                    <ListItemIcon>
                                        <ForecastIcon color={selected.includes("/forecast") ? "#4DA685" : "#272931"}/>

                                    </ListItemIcon>
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>

                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "P(Close)" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                to="/pclose"
                                selected={selected.includes("/pclose")}
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["pclose"].hasAccess !== true}
                            >
                                <ListItemButton dense disableGutters component="a">
                                    <ListItemIcon>
                                        <PCloseIcon size={'1.2em'}
                                                    color={selected.includes("/pclose") ? "#4DA685" : "#272931"}
                                        />
                                    </ListItemIcon>
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>

                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "P(Convert)" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                selected={selected.includes("/pconvert")}
                                to="/pconvert"
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["pconvert"].hasAccess !== true}
                            >
                                <ListItemButton dense disableGutters component="a">
                                    <ListItemIcon>
                                        <PConvertIcon
                                            color={selected.includes("/pconvert") ? "#4DA685" : "#272931"}
                                        />
                                    </ListItemIcon>
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>


                    {/*<div style={{display:"none"}}>*/}

                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Board Stats" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                to="/board-stats"
                                selected={selected.includes("/board-stats")}
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["board-stats"].hasAccess !== true}>

                                <ListItemButton dense disableGutters component="a"
                                >
                                    <ListItemIcon>
                                        <BoardStatsIcon
                                            color={selected.includes("/board-stats") ? "#4DA685" : "#272931"}
                                        />
                                    </ListItemIcon>
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>

                    {/*    </div>*/}
                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "GTM Stats" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                selected={selected.includes("/gtm-stats")}
                                to="/gtm-stats"
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["gtm-stats"].hasAccess !== true}
                            >
                                <ListItemButton dense disableGutters component="a"
                                >
                                    <ListItemIcon>
                                        <GtmStatsIcon
                                            color={selected.includes("/gtm-stats") ? "#4DA685" : "#272931"}/>
                                    </ListItemIcon>
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>

                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Opp Creation" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                to="/opp-creation"
                                selected={selected.includes("/opp-creation")}
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["opp-creation"].hasAccess !== true}
                            >
                                <ListItemButton dense disableGutters component="a"
                                >
                                    <ListItemIcon>
                                        <OpportunityCreationIcon
                                            color={selected.includes("/opp-creation") ? "#4DA685" : "#272931"}
                                        />
                                    </ListItemIcon>
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>


                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Win Rates" : "Complete setup to access"}</span>}
                        // TransitionComponent={Zoom}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                selected={selected.includes("/win-rate")}
                                to="/win-rate"
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["win-rate"].hasAccess !== true}
                            >
                                <ListItemButton dense disableGutters component="a">
                                    <ListItemIcon>
                                        <BookingTrendsIcon
                                            color={selected.includes("/win-rate") ? "#4DA685" : "#272931"}
                                        />
                                    </ListItemIcon>
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>


                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Inbound Follow-Up" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                to="/inbound-follow-up"
                                selected={selected.includes("/inbound-follow-up")}
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["inbound-follow-up"].hasAccess !== true}
                            >
                                <ListItemButton dense disableGutters component="a">
                                    <ListItemIcon>
                                        <InboundFollowupIcon
                                            color={selected.includes("/inbound-follow-up") ? "#4DA685" : "#272931"}
                                        />
                                    </ListItemIcon>
                                    {/*<ListItemText primary="Inbound Follow-Up"/>*/}
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>
                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "GTM Activity" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                to="/gtm-activity"
                                selected={selected.includes("/gtm-activity")}
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["gtm-activity"].hasAccess !== true}
                            >
                                <ListItemButton dense disableGutters component="a">
                                    <ListItemIcon>
                                        <ActivityHeatmapIcon
                                            color={selected.includes("/gtm-activity") ? "#4DA685" : "#272931"}
                                        />
                                    </ListItemIcon>
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>

                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Rep Ramp" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                to="/rep-ramp"
                                selected={selected.includes("/rep-ramp")}
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["rep-ramp"].hasAccess !== true}
                            >
                                <ListItemButton dense disableGutters component="a">
                                    <ListItemIcon>
                                        <div style={{paddingLeft: "3px"}}>
                                            <SalesRampIcon
                                                color={selected.includes("/rep-ramp") ? "#4DA685" : "#272931"}
                                            />
                                        </div>
                                    </ListItemIcon>
                                    {/*<ListItemText primary="Rep Ramp"/>*/}
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>

                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Pipeline Forensics" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                to="/pipeline-forensics"
                                selected={selected.includes("/pipeline-forensics")}
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["pipeline-forensics"].hasAccess !== true}
                            >
                                <ListItemButton dense disableGutters component="a"
                                >
                                    <ListItemIcon>
                                        <PipelineAnalyticsIcon
                                            color={selected.includes("/pipeline-forensics") ? "#4DA685" : "#272931"}
                                        />
                                    </ListItemIcon>
                                    {/*<ListItemText primary="Pipeline Analytics"/>*/}
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>

                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Program Effectiveness" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                to="/program-effectiveness"
                                selected={selected.includes("/program-effectiveness")}
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["program-effectiveness"].hasAccess !== true}
                            >
                                <ListItemButton dense disableGutters component="a"
                                >
                                    <ListItemIcon
                                    >
                                        <NodeEffectivenessIcon
                                            color={selected.includes("/program-effectiveness") ? "#4DA685" : "#272931"}
                                        />
                                    </ListItemIcon>
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>

                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Win Attribution" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                to="/winflows"
                                selected={selected.includes("/winflows")}
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["winflows"].hasAccess !== true}
                            >
                                <ListItemButton dense disableGutters component="a">
                                    <ListItemIcon>
                                        <ClosedWonFlowsIcon
                                            color={selected.includes("/winflows") ? "#4DA685" : "#272931"}
                                        />
                                    </ListItemIcon>
                                    {/*<ListItemText primary="Win Attribution"/>*/}
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>

                    <Tooltip title={<span
                        style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Pipeline Attribution" : "Complete setup to access"}</span>}
                             placement="right">
                        <div>
                            <QNavMenuItem
                                to="/pipelineflows"
                                selected={selected.includes("/pipelineflows")}
                                disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["pipelineflows"].hasAccess !== true}
                            >

                                <ListItemButton dense disableGutters component="a"
                                >
                                    <ListItemIcon>
                                        <PipelineFlowsIcon
                                            color={selected.includes("/pipelineflows") ? "#4DA685" : "#272931"}
                                        />
                                    </ListItemIcon>
                                    {/*<ListItemText primary="Pipeline Attribution"/>*/}
                                </ListItemButton>
                            </QNavMenuItem>
                        </div>
                    </Tooltip>


                    { process.env.REACT_APP_STAGE === 'dev' &&
                    <div>
                        <Tooltip title={<span
                            style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Account Management" : "Complete setup to access"}</span>}
                                 placement="right">
                            <div>
                                <QNavMenuItem
                                    selected={selected.includes("/account-management")}
                                    to="/account-management"
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["config"].hasAccess !== true}
                                >
                                    <ListItemButton dense disableGutters component="a">
                                        <ListItemIcon color="secondaryNew">
                                            <AccountManagementIcon
                                                color={selected.includes("/account-management") ? "#4DA685" : "#272931"}
                                            />
                                        </ListItemIcon>
                                        {/*<ListItemText primary="Campaign Config"/>*/}
                                    </ListItemButton>
                                </QNavMenuItem>
                            </div>
                        </Tooltip>
                    </div>
                    }
                    <div>
                        <Tooltip title={<span
                            style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Campaign Spend" : "Complete setup to access"}</span>}
                                 placement="right">
                            <div>
                                <QNavMenuItem
                                    selected={selected.includes("/config")}
                                    to="/config/programs"
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["config"].hasAccess !== true}
                                >
                                    <ListItemButton dense disableGutters component="a">
                                        <ListItemIcon color="secondaryNew">
                                            <ConfigIcon
                                                color={selected.includes("/config/programs") ? "#4DA685" : "#272931"}
                                            />
                                        </ListItemIcon>
                                        {/*<ListItemText primary="Campaign Config"/>*/}
                                    </ListItemButton>
                                </QNavMenuItem>
                            </div>
                        </Tooltip>
                    </div>

                    <div>
                        <Tooltip title={<span
                            style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Customer Invoices" : "Complete setup to access"}</span>}
                                 placement="right">
                            <div>
                                <QNavMenuItem
                                    to="/customer-invoices"
                                    selected={selected.includes("/customer-invoices")}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["customer-invoices"].hasAccess !== true}
                                >
                                    <ListItemButton dense disableGutters component="a"
                                    >
                                        <ListItemIcon color="secondaryNew">
                                            <CustomerInvoicesIcon
                                                color={selected.includes("/customer-invoices") ? "#4DA685" : "#272931"}
                                            />
                                        </ListItemIcon>
                                        {/*<ListItemText primary="Campaign Config"/>*/}
                                    </ListItemButton>
                                </QNavMenuItem>
                            </div>
                        </Tooltip>
                    </div>

                    {acceptedBookingMetric !== "amount_field" && process.env.REACT_APP_STAGE === 'dev' &&
                        <div>
                            <Tooltip title={<span
                                style={{fontSize: '1.35em'}}>{dataSetupComplete ? "ARR Schedule" : "Complete setup to access"}</span>}
                                     placement="right">
                                <div>
                                    <QNavMenuItem
                                        to="/arr-schedule"
                                        selected={selected.includes("/arr-schedule")}
                                        disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["arr-schedule"].hasAccess !== true}
                                    >
                                        <ListItemButton dense disableGutters component="a"
                                        >
                                            <ListItemIcon color="secondaryNew">
                                                <ArrScheduleIcon
                                                    color={selected.includes("/arr-schedule") ? "#4DA685" : "#272931"}
                                                />
                                            </ListItemIcon>
                                            {/*<ListItemText primary="Campaign Config"/>*/}
                                        </ListItemButton>
                                    </QNavMenuItem>
                                </div>
                            </Tooltip>
                        </div>
                    }

                    <div>
                        <Tooltip title={<span
                            style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Department Spend" : "Complete setup to access"}</span>}
                                 placement="right">
                            <div>
                                <QNavMenuItem
                                    to="/department-spend"
                                    selected={selected.includes("/department-spend")}
                                    disabled={excludeDataGenTrialEnded || !dataSetupComplete || currUserPermissions[currUser.role]["department-spend"].hasAccess !== true}
                                >
                                    <ListItemButton dense disableGutters component="a"
                                    >
                                        <ListItemIcon color="secondaryNew">
                                            <DepartmentSpendIcon
                                                color={selected.includes("/department-spend") ? "#4DA685" : "#272931"}
                                            />
                                        </ListItemIcon>
                                        {/*<ListItemText primary="Campaign Config"/>*/}
                                    </ListItemButton>
                                </QNavMenuItem>
                            </div>
                        </Tooltip>
                    </div>

                    {(currUser.email.indexOf("@qflow.ai") > -1 || currUser.email.indexOf("blossom") > -1 || currUser.email.indexOf("arosten") > -1) &&
                        <Tooltip title={<span
                            style={{fontSize: '1.35em'}}>{dataSetupComplete ? "Manage Prompts" : "Complete setup to access"}</span>}
                                 placement="right">
                            <div>
                                <QNavMenuItem
                                    to="/manage-prompts"
                                    selected={selected.includes("/manage-prompts")}
                                >
                                    <ListItemButton dense disableGutters component="a"
                                    >
                                        <ListItemIcon color="secondaryNew">
                                            <PromptsIcon
                                                color={selected.includes("/manage-prompts") ? "#10a983" : "#272931"}
                                            />
                                        </ListItemIcon>
                                        {/*<ListItemText primary="Campaign Config"/>*/}
                                    </ListItemButton>
                                </QNavMenuItem>
                            </div>
                        </Tooltip>
                    }


                </Box>


            }
            <NotSetupSnackBar
                snackbarState={snackbarState}
                handleClose={handleClose}
                message={`Agent ${currUser.lastName}, your account does not yet have access to this module.`}/>
        </div>
        // {/*</Router>*/}
    );
}

export default React.memo(QNavListItems);