// createIDBPersister.js
import { get, set, del, createStore } from 'idb-keyval'

export function createIDBPersister({
  dbName = 'reactQueryDB',
  storeName = 'reactQueryStore',
  key = 'reactQuery',
  //     serialize = JSON.stringify,
  // deserialize = JSON.parse,
} = {}) {
  const store = createStore(dbName, storeName)

  return {
    persistClient: async (client) => {
      await set(key, client, store)
    },
    restoreClient: async () => {
      return await get(key, store)
    },
    removeClient: async () => {
      await del(key, store)
    },
  }
}
