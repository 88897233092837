const dev = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "qflow-app-api-dev-attachmentsbucket-1vi6bvz0ckdb1"
    },
    driftSignedIdentity: 'Nuqj26lwPSGbpuvkoUuZ+3hPeAAmMiUsgmiIzSzcAow=',
    websocket: {
        // URL: 'wss://uuex06c872.execute-api.us-west-2.amazonaws.com/test/'
        URL: 'wss://l7bvan77x2.execute-api.us-west-2.amazonaws.com/test/'
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://64e0njw17i.execute-api.us-west-2.amazonaws.com/dev"
    },
    hubspotIntegration: {
        CLIENT_ID: "70f33c78-d190-499d-91f4-f6f37ae51658",
        // REDIRECT_URL: "https://test.qflow.ai/admin/company/create/1/hubspot",
        REDIRECT_URL: "https://test.qflow.ai/admin/company/create/1/hubspot",
        OAUTHURL: "https://app.hubspot.com/oauth/authorize?client_id=70f33c78-d190-499d-91f4-f6f37ae51658&redirect_uri=https://test.qflow.ai/admin/company/create/1/hubspot" +
            "&scope=forms" +
            "&optional_scope=crm.lists.read%20crm.objects.contacts.read%20crm.objects.marketing_events.read%20crm.schemas.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.schemas.companies.read%20crm.schemas.deals.read%20crm.objects.owners.read%20crm.objects.quotes.read%20crm.schemas.quotes.read%20crm.objects.line_items.read%20crm.schemas.line_items.read%20crm.schemas.custom.read%20crm.objects.custom.read%20settings.users.read%20settings.users.teams.read%20crm.objects.goals.read%20business_units_view.read%20content%20settings.currencies.read%20crm.export"
    },

    hubspotCrmIntegration: {
        CLIENT_ID: "2ef28bf1-7e06-4502-88e5-7366016047a6",
        REDIRECT_URL: "https://test.qflow.ai/admin/company/create/0/hubspot_crm",
        OAUTHURL: "https://app.hubspot.com/oauth/authorize?client_id=2ef28bf1-7e06-4502-88e5-7366016047a6&redirect_uri=https://test.qflow.ai/admin/company/create/0/hubspot_crm" +
            "&scope=crm.schemas.quotes.read%20crm.objects.line_items.read%20crm.schemas.deals.read%20crm.schemas.line_items.read%20crm.objects.owners.read%20forms%20crm.objects.marketing_events.read%20crm.objects.companies.read%20crm.lists.read%20crm.objects.deals.read%20crm.schemas.contacts.read%20crm.objects.contacts.read%20crm.schemas.companies.read%20crm.objects.quotes.read"
            + "&optional_scope=crm.export%20content%20settings.currencies.read%20crm.schemas.custom.read%20business_units_view.read%20crm.objects.custom.read%20sales-email-read%20crm.objects.goals.read%20settings.users.teams.read%20settings.users.read"

},
    // salesforceIntegration: {
    //     SALESFORCE_CONSUMER_KEY: '3MVG95jctIhbyCprt_aWLbFonglrSIhTXncrsm2kfWS9Yw.wh5X2in.i7hwV2ZEt0sGdqBU5i4ehTlzhj3iQT',
    //     SALESFORCE_REDIRECT_URI: 'https://qflow.ai/admin/company/create/0'
    // },
    //     salesforceIntegration: {
    //     SALESFORCE_CONSUMER_KEY: '3MVG95jctIhbyCprt_aWLbFonggKF0nn8LupYKItMkD5h6KJfc1n1kZzD7361AB5ETVtBe.CNx3Zlm9wSUULv',
    //     SALESFORCE_REDIRECT_URI: 'https://test.qflow.ai/admin/company/create/0',
    // },
    salesforceIntegration: {
        // DEV
        // SALESFORCE_CONSUMER_KEY: "3MVG95jctIhbyCprt_aWLbFonggKF0nn8LupYKItMkD5h6KJfc1n1kZzD7361AB5ETVtBe.CNx3Zlm9wSUULv",
        // SALESFORCE_REDIRECT_URI: 'https://test.qflow.ai/admin/company/create/0/sfdc',

        //PROD
        // QFlowai_ML_Insights_for_Salesforce
        SALESFORCE_CONSUMER_KEY: '3MVG95jctIhbyCprt_aWLbFonglrSIhTXncrsm2kfWS9Yw.wh5X2in.i7hwV2ZEt0sGdqBU5i4ehTlzhj3iQT',
        SALESFORCE_REDIRECT_URI: 'https://qflow.ai/admin/company/create/0/sfdc'
    },
    qboIntegration: {
        CLIENT_ID: "ABkQ9eCn4ubR7tY55QoclaUov9jbuPNqlxJwtPYrFqLMMOPl5V",
        REDIRECT_URI: "https://test.qflow.ai/admin/company/create/2/qbo"
    },
    slackIntegration: {
        redirectUri: "/connect-slack",
        client_id: "698531060071.1506238408452",
        client_secret: "201202c228db452a2fb470b6c85505ff",
    },
    gongIntegration: {
      redirectUri: "/connect-gong",
      client_id: "jqedon3ms",
      client_secret: "/5kRdsNNrOQBSgAHfniAhPmvwHb6LqbgobA8slEv/phV"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_CQkFgh1db",
        APP_CLIENT_ID: "46cso2ccd3qlt440hhdgipebk5",
        IDENTITY_POOL_ID: "us-west-2:ed3c7705-79cf-48a7-81db-7a66ba36392c",
        oauth: {

            domain: 'auth.qflow.ai',
            scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile", "phone"],
            redirectSignIn: '/identityredirect',
            redirectSignOut: 'https://test.qflow.ai/login',
            responseType: 'code'
        }
    },
    // stripeKeyName: "/stripeSecretKey/test",
    STRIPE_KEY: "pk_test_40LkZYYBCgp8xmd4kSWwi9glMuvakF1cEOwTbvAylSnOs3sRthCyr9rfAMmfIppsemnhNzWJhNhSJj3BosuU4xHqg00pU9D2dCw",
    // STRIPE_MONTHLY: "price_0H4yN4YYBCgp8xmd0D2xQ9je",
    // STRIPE_QUARTERLY: "price_0H85vkYYBCgp8xmdIsIz0ZoN",
    // STRIPE_ANNUALLY: "price_0H85vkYYBCgp8xmdqq9DjDFZ",
    // stripePricing: {
    //     'QFlow.ai Marketing': {
    //         STRIPE_MONTHLY: "STRIPE_MONTHLY",
    //         STRIPE_QUARTERLY: "STRIPE_QUARTERLY",
    //         STRIPE_ANNUALLY: "STRIPE_ANNUALLY",
    //     },
    //     'QFlow.ai Sales': {
    //         STRIPE_MONTHLY: "price_0HdMmSYYBCgp8xmd4RXeSDvl",
    //         STRIPE_QUARTERLY: "STRIPE_QUARTERLY",
    //         STRIPE_ANNUALLY: "STRIPE_ANNUALLY",
    //     },
    //     'QFlow.ai Business': {
    //         STRIPE_MONTHLY: "STRIPE_MONTHLY",
    //         STRIPE_QUARTERLY: "STRIPE_QUARTERLY",
    //         STRIPE_ANNUALLY: "STRIPE_ANNUALLY",
    //     }
    // },

    REACT_APP_BACKEND_HOST: "https://api.qflow.ai/test",
    REACT_APP_FRONTEND_HOST: "https://test.qflow.ai"
    // REACT_APP_BACKEND_HOST: "https://api.qflow.ai/prod",
};


const prod = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "qflow-app-api-prod-attachmentsbucket-1utusgixglmaz"
    },
    websocket: {
        // URL: 'wss://dk5dsbdv04.execute-api.us-west-2.amazonaws.com/prod/'
        URL: 'wss://0lbx6ruka0.execute-api.us-west-2.amazonaws.com/prod/'
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://64e0njw17i.execute-api.us-west-2.amazonaws.com/prod"
    },
    salesforceIntegration: {
        //QFlowai_ML_Insights_for_Salesforce
        SALESFORCE_CONSUMER_KEY: '3MVG95jctIhbyCprt_aWLbFonglrSIhTXncrsm2kfWS9Yw.wh5X2in.i7hwV2ZEt0sGdqBU5i4ehTlzhj3iQT',
        SALESFORCE_REDIRECT_URI: 'https://qflow.ai/admin/company/create/0/sfdc'
    },
    slackIntegration: {
        redirectUri: "/connect-slack",
        client_id: "698531060071.1506238408452",
        client_secret: "201202c228db452a2fb470b6c85505ff",
    },
    gongIntegration: {
      redirectUri: "/connect-gong",
      client_id: "jqedon3ms",
      client_secret: "/5kRdsNNrOQBSgAHfniAhPmvwHb6LqbgobA8slEv/phV"
    },

    hubspotIntegration: {
        CLIENT_ID: "2391c5a3-de98-40e9-ba2d-6b6f1f68c3e7",
        REDIRECT_URL: "https://qflow.ai/admin/company/create/1/hubspot",
        OAUTHURL: "https://app.hubspot.com/oauth/authorize?client_id=2391c5a3-de98-40e9-ba2d-6b6f1f68c3e7&redirect_uri=https://qflow.ai/admin/company/create/1/hubspot" +
            "&scope=forms" +
            "&optional_scope=crm.lists.read%20crm.objects.contacts.read%20crm.objects.marketing_events.read%20crm.schemas.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.schemas.companies.read%20crm.schemas.deals.read%20crm.objects.owners.read%20crm.objects.quotes.read%20crm.schemas.quotes.read%20crm.objects.line_items.read%20crm.schemas.line_items.read%20crm.schemas.custom.read%20crm.objects.custom.read%20settings.users.read%20settings.users.teams.read%20crm.objects.goals.read%20business_units_view.read%20content%20settings.currencies.read%20crm.export"
    },
    hubspotCrmIntegration: {
        CLIENT_ID: "e02f3c51-943b-45bb-892e-9b43fb2cf2f4",
        // REDIRECT_URL: "https://test.qflow.ai/admin/company/create/1/hubspot",
        REDIRECT_URL: "https://qflow.ai/admin/company/create/0/hubspot_crm",
        OAUTHURL: "https://app.hubspot.com/oauth/authorize?client_id=e02f3c51-943b-45bb-892e-9b43fb2cf2f4&redirect_uri=https://qflow.ai/admin/company/create/0/hubspot_crm" +
            "&scope=forms%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.marketing_events.read%20crm.schemas.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.schemas.companies.read%20crm.schemas.deals.read%20crm.objects.owners.read%20crm.objects.quotes.read%20crm.schemas.quotes.read%20crm.objects.line_items.read%20crm.schemas.line_items.read"
            + "&optional_scope=sales-email-read%20crm.schemas.custom.read%20crm.objects.custom.read%20settings.users.read%20settings.users.teams.read%20crm.objects.goals.read%20business_units_view.read%20content%20settings.currencies.read%20crm.export"
    },

    qboIntegration: {
        CLIENT_ID: "ABACWA7syogFoEmqymKwzpFbVqMdDjNuPXLbjRgrjHsS5QnkHn",
        REDIRECT_URI: "https://qflow.ai/admin/company/create/2/qbo"

    },

    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_CQkFgh1db",
        APP_CLIENT_ID: "46cso2ccd3qlt440hhdgipebk5",
        IDENTITY_POOL_ID: "us-west-2:ed3c7705-79cf-48a7-81db-7a66ba36392c",
        oauth: {
            // domain: 'auth.qflow.ai',

            // client id: 0oarhah6kOF5eysik5d6
            // client_secret: mtcZLrTGLBHL1A2qeHrFuBLVKB-Lsni_rjvf63B_
            // issuer_url: https://dev-19122564.okta.com

            domain: 'auth.qflow.ai',
            // scope: ['email', 'profile', 'openid'],
            scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile", "phone"],
            redirectSignIn: 'https://qflow.ai/identityredirect',
            redirectSignOut: 'https://qflow.ai/login',
            responseType: 'code'
        }
    },
    STRIPE_KEY: "pk_BeDkzPNDMS9dnjjJ99MoHVtc4hLIZ",
    // // stripeKeyName: "/stripeSecretKey/live",
    // STRIPE_MONTHLY: "price_0HHyx9YYBCgp8xmd8sfgfkwQ",
    // STRIPE_QUARTERLY: "price_0HHyx9YYBCgp8xmdU9Wbeb2D",
    // STRIPE_ANNUALLY: "price_0HHyx8YYBCgp8xmdH6QokUQm",
    // stripePricing: {
    //     'QFlow.ai Marketing': {
    //         STRIPE_MONTHLY: "STRIPE_MONTHLY",
    //         STRIPE_QUARTERLY: "STRIPE_QUARTERLY",
    //         STRIPE_ANNUALLY: "STRIPE_ANNUALLY",
    //     },
    //     'QFlow.ai Sales': {
    //         STRIPE_MONTHLY: "price_0HdMmSYYBCgp8xmd4RXeSDvl",
    //         STRIPE_QUARTERLY: "STRIPE_QUARTERLY",
    //         STRIPE_ANNUALLY: "STRIPE_ANNUALLY",
    //     },
    //     'QFlow.ai Business': {
    //         STRIPE_MONTHLY: "STRIPE_MONTHLY",
    //         STRIPE_QUARTERLY: "STRIPE_QUARTERLY",
    //         STRIPE_ANNUALLY: "STRIPE_ANNUALLY",
    //     }
    // },


    // REACT_APP_BACKEND_HOST: "https://64e0njw17i.execute-api.us-west-2.amazonaws.com/prod",
    REACT_APP_BACKEND_HOST: "https://api.qflow.ai/prod",
    REACT_APP_FRONTEND_HOST: "https://qflow.ai"

};

// // Default to dev if not set
// export const config = process.env.REACT_APP_STAGE === 'prod'
//   ? prod
//   : dev;

export const config = process.env.REACT_APP_STAGE === 'dev' ? dev : prod;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};
