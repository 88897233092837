import React from "react";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";

function QNavMenuItem({ to, children, ...menuItemProps }) {
  return (
    <MenuItem
      {...menuItemProps}
      component={Link}    // Tells MUI to render this as a React Router <Link> underneath
      to={to}            // React Router "to" attribute for client-side navigation
      sx={{
        backgroundColor: "#FFF",
        "& .MuiListItemIcon-root": {
          color: "#272931",
          margin: 0,
          "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
            color: "#4DA685",
          },
        },
        "& .MuiListItemButton-root": {
          backgroundColor: "transparent",
          "&:hover": { backgroundColor: "transparent" }
        },
      }}
    >
      {children}
    </MenuItem>
  );
}

export default QNavMenuItem;
